html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
HTML5 display-role reset for older browsers article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "coder";
  src: local("coder"), url(font.woff) format("woff");
}

body {
  margin: 0;
  font-family: "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  -ms-overflow-style: none;
}
/* IE and Edge
/* Firefox*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.imgCenter {
  z-index: 6;
  transform: perspective(3000px) rotateY(5deg) !important;
  transition: transform 0.5s ease 0s !important;
  border-radius: 10px !important;
}
.imgCenter:hover {
  transform: perspective(1500px) rotateY(0.5deg) !important;
}

.backgroundImg {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  width: 50%;
  height: 100%;
  -webkit-filter: blur(25px);
  filter: blur(25px);
}
@media (max-width: 600px) and (min-width: 500px) {
  .backgroundImg {
    width: 100%;
    height: 40%;
  }
  .title {
    font-size: 3rem !important;
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .backgroundImg {
    width: 100%;
    height: 40%;
  }
  .mainContainer {
    margin-top: 10px;
    margin-left: -20px !important;
    padding: 10px !important;
  }
  .title {
    font-size: 2rem !important;
    margin-bottom: 20px;
  }
  .imgCenter {
    margin-left: 20px;
    width: 15rem !important;
  }
  .header {
    width: fit-content;
  }
}
.header {
  background: -webkit-linear-gradient(left, #fde68a, #f59e0b);
  color: rgb(42, 56, 74);
  font-size: 1.25rem;
  font-weight: 900;
  text-align: center;

  z-index: 3;
  width: 100%;

  padding: 15px;
}

.title {
  text-align: center;
  font-weight: 900 !important;
  width: 100%;
}
div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}
